import { Typography } from '@mms/mms-ui-library';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import { ArrowUpIcon } from '@/assets/inputs/ArrowUpIcon';

export const Wrapper = styled(Stack)(({ theme }) => ({
	justifyContent: 'space-between',
	width: 54,
	height: 30,
	backgroundColor: theme.palette.buttons.numberBackground,
	borderRadius: 3,
	padding: '7px',
}));

export const Value = styled(Typography)(({ theme }) => ({
	lineHeight: theme.typography.pxToRem(19),
	color: theme.palette.buttons.numberMain,
}));

export const ControlsWrapper = styled(Stack)({
	justifyContent: 'space-between',
	width: 8,
	height: 16,
});

export const StyledIconButton = styled(IconButton)({
	padding: 0,
});

export const StyledArrowUpIcon = styled(ArrowUpIcon)(({ theme }) => ({
	width: 10,
	height: 6,
	color: theme.palette.buttons.numberMain,
}));

export const StyledArrowDownIcon = styled(StyledArrowUpIcon)({
	transform: 'rotate(180deg)',
});
