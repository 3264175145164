import { Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { Tag, Typography } from '@mms/mms-ui-library';

import { FieldHeaderWrapper } from '../FieldHeaderWrapper';

import {
	DEFAULT_DISABLED_INPUT_COMPONENT_OPACITY,
	DEFAULT_OPACITY,
} from '@/constants/opacities';
import { BOOKING_DEFAULT_ICON_SIZE } from '@/pages/Booking/constants';

const TAG_HEIGHT = 21;
const LINE_HEIGHT = '16px';
const DIFFERENCE_CONTAINER_WIDTH = 40;
const COLOR_OPACITY = '4D';

const getTagColor = (theme: Theme, isAvailable: boolean) =>
	`${
		theme.palette[isAvailable ? 'feedback-success' : 'feedback-danger']
	}${COLOR_OPACITY}`;

export const TimeSelectorsWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column' as const,
	gap: theme.spaces.m,
	width: '100%',
}));

export const TimeSelectorsContentWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	gap: theme.spaces.s,
}));

export const TimeSelectorsHeader = styled(FieldHeaderWrapper)(() => ({}));

export const IconWrapper = styled('div')(() => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: BOOKING_DEFAULT_ICON_SIZE,
	height: BOOKING_DEFAULT_ICON_SIZE,
}));

export const TimeRangeDifferenceContainer = styled('div')(
	() =>
		({
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			width: DIFFERENCE_CONTAINER_WIDTH,
			flexShrink: 0,
		} as const)
);

export const TimeRangeDifferenceTypography = styled(Typography)<{
	disabled?: boolean;
}>(({ theme, disabled }) => ({
	lineHeight: LINE_HEIGHT,
	color: theme.palette['base-6'],
	opacity: disabled
		? DEFAULT_DISABLED_INPUT_COMPONENT_OPACITY
		: DEFAULT_OPACITY,
}));

export const AvailabilityTag = styled(Tag)<{ isAvailable: boolean }>(
	({ theme, isAvailable }) => ({
		height: TAG_HEIGHT,
		padding: `0 ${theme.spaces.s}px`,
		border: 'none',
		marginLeft: theme.spaces.m,

		'&, &:hover': {
			backgroundColor: getTagColor(theme, isAvailable),
		},
	})
);
