export const Routes = {
	home: '/',
	dashboard: '/dashboard',
	reports: '/reports',
	timeSheet: '/time-sheet',
	clients: '/clients',
	projects: '/projects',
	users: '/users',
	signIn: '/sign-in',
	authCallback: '/auth-callback',
	signOut: '/sign-out',
	departments: '/departments',
	updateProfile: '/update-profile',
	setProfile: '/set-profile',
	booking: '/booking',
	equipment: '/equipment',
	adminPanel: '/admin-panel',
	teamBoard: '/team-board',
	teamBoardUserId: '/team-board/:id',
	performanceReview: '/performance-review',
	all: '*',
};

export const routesNotAssignableToPathKey = [
	Routes.authCallback,
	Routes.signIn,
];

export type RoutesType = typeof Routes;
