export const MORE_THEN_THREE = 'Must be more than three characters';
export const NOT_VALID_PHONE_FORMAT = 'Invalid phone number format';
export const NOT_VALID_EMAIL =
	'Enter a valid Email in the “example@modsen-software.com” format';
export const NOT_VALID_EMAIL_FORMAT = 'Invalid email address format';
export const NOT_VALID_EMAIL_DOMAIN = 'Invalid email address domain';
export const EMAIL_IS_ALREADY_USED = 'Email address is already used';
export const NOT_VALID_COMMENT = 'Enter a valid Note';
export const TOO_MUCH = 'Too much characters';
export const FILLED_IN = 'Must be filled in';
export const NOT_FOUND_MESSAGE = `404 - PAGE NOT FOUND`;
export const NOT_ONLY_SPACES = 'Value must not contain only spaces';
export const NO_DATA_FOUND = 'No data found';
export const TOKEN_EXPIRED = 'Token has already expired!';
export const ACCESS_DENIED = 'Access denied';
export const MODSEN_DOMAIN = 'Must be in modsen-software domain!';
export const NOT_ENTER_VALUE = 'Enter';
export const CHOOSE_PROJECT_FIRST = 'Select Project';
export const PROJECT_FIRST = 'Project first';
export const USER_IS_INACTIVE = 'Inactive user';
export const FIELD_EDIT_FORBIDDEN = 'Field edit forbidden';
export const EDITING_IS_FORBIDDEN = 'Editing is forbidden';
export const FIRST_NEED_TRACK_HOURS =
	'First you need to track some hours for the last task';
export const ADD_TIME_FORBIDDEN =
	'Add time is forbidden after 3rd day of the next month';
export const PROJECT_NOT_MORE_AVAILABLE =
	'Project is not further available to you';
export const PROJECT_UPDATED_BUT_NOT_AVAILABLE =
	'Project updated, but not further available to you';
export const AXIOS_ERROR = 'AxiosError';
export const SELECT_DESK = 'Please, select a desk';
export const SELECT_ROOM = 'Please, select a room';
export const SELECT_DATE = 'Please, select a date';
export const BOOKING_ALREADY_EXISTS = 'Booking for this date already exists';
export const SOMETHING_WENT_WRONG = 'Something went wrong';
export const PROJECT_NAME_MUST_BE_FILLED_IN = 'Enter Project name';
export const CLIENT_NAME_MUST_BE_FILLED_IN = 'Enter Client name';
export const NOT_VALID_DATE = 'Enter a valid date';
export const EMPTY_DATE = 'Enter the Date';
export const EMAIL_MUST_BE_FILLED_IN = 'Enter Email';
export const DEPARTMENT_MUST_BE_FILLED_IN = 'The Department field is required';
export const LOCATION_MUST_BE_FILLED_IN = 'The Location field is required';
export const POSITION_MUST_BE_FILLED_IN = 'The Position field is required';
export const SELECT_STACK = 'Select Stack';
export const NOT_SELECTED_USER = 'Select User';
export const NO_DATA_EQUIPMENT = 'There is no data to display';
export const RESPONSE_USER_WITH_ROLE_EXISTS_START = 'A User with';
export const RESPONSE_USER_WITH_ROLE_EXISTS_END = 'role is already existed';
export const PROJECT_IS_INECTIVE = 'Inactive project';
export const NO_ACCESS = 'No access';
export const REPORT_GENERATING_FAILED =
	'Report file generation is failed. Please try again.';

export const PROJECT_CANNOT_BE_DEACTIVATED =
	'Project can not be deactivated. To deactivate project you should remove assigned PM and users from the project';

export const RESPONSE_EMAIL_EXIST = 'Email address is already used';

export const RESPONSE_USER_WITH_EMAIL_EXISTS =
	'User with this email already exists!';
export const FIELD_IS_REQUIRED = 'This field is required';

export const RESPONSE_PHONE_EXIST = 'Phone number is already used';

export const getMinLengthErrorMessage = (limitValue: number) =>
	`Min. length is ${limitValue} symb.`;

export const getMaxLengthErrorMessage = (limitValue: number) =>
	`Max. length is ${limitValue} symb.`;

export const getPleaseEnterAValidFieldErrorMessage = (fieldName: string) =>
	`Please enter a valid ${fieldName}`;

export const CANNOT_GRANT_PERMISSION_WITHOUT_USER_DEPARTMENT =
	'Cannot grant permissions to user without specified department';

export const DATE_RANGE_IS_INVALID = 'The date range is invalid';
export const PROJECT_ASSIGNMENT_ISSUE = {
	title: 'Project assignment issue',
	body: 'Project assignment for this period already exisst. Please check and try again',
};

export const TIMESHEET_ISSUE = {
	title: 'Timesheets issue',
	body: 'Timesheet is filled for this period. Please check and try again',
};

export const VALUE_MUST_BE_AT_LEAST_0 = 'Value must be at least 0';
export const VALUE_MUST_BE_AT_MOST_1 = 'Value must be at most 1';
export const expiredReportLinkToast = {
	title: 'File source issue',
	body: 'Report file not found. Please try to generate report again',
};

export const SAME_REPORT_GENERATING =
	'The same report is being generated by the moment';

export const EDITING_CEO_USER_RESPONSE = 'You cannot edit users with CEO role';
export const EDITING_CEO_USER_FORBIDDEN = 'You can’t edit users with CEO role';

export const CLIENT_CANNOT_BE_DEACTIVATED =
	"The client can't be deactivated. Please ensure the client doesn’t have any active projects";

export const CLIENT_CANNOT_BE_DEACTIVATED_TOOLTIP =
	'You can’t edit deactivated client';
