import {
	PropsWithChildren,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { Timeout } from 'react-number-format/types/types';
import { useLocation, useNavigate } from 'react-router-dom';

import { LogOutPopUp } from '@/components/LogOutPopUp';
import {
	BROADCAST_CHANNEL_NAME,
	INACTIVE_LIMIT,
	LOGOUT_REASON,
	LOG_OUT_STATUS,
	LogoutReasons,
	LogoutStatuses,
	PATH_KEY,
	SESSION_DURATION,
	SESSION_EXPIRES,
	SHOWING_LOG_OUT_POPUP_DURATION,
	USER_ACTIVITY_EVENTS,
	USER_LAST_ACTIVITY_DATE,
} from '@/constants/authConstants';
import { NotificationType, Routes } from '@/constants/index';
import { routesNotAssignableToPathKey } from '@/constants/routes';
import { createToast } from '@/helpers/createToast';
import {
	getAuthState,
	initialAuthState,
	isUserLoggedIn,
	removeAuthState,
	setAuthState,
} from '@/helpers/localStorage';
import { useAxiosInterCeptors } from '@/hooks/useAxiosInterceptors';
import { useLogout } from '@/queries/useLogout';
import { useUserInfo } from '@/queries/useUserInfo';

import { useToast } from './Toast';
import {
	AuthState,
	HandleReceivedToken,
	InitialAuthState,
	SessionBroadcastEvents,
	SessionBroadcastMessage,
} from './types';

const { VIA_BUTTON, SESSION_EXPIRED, INACTIVITY } = LogoutReasons;

// Variable containing the date of the last user activity
let lastUserActivityDate = Date.now();
// Function to notify the current and other tabs in the application that the user was active
function notifyCurrentAndOtherTabsUserWasActive() {
	// Update last activity time
	lastUserActivityDate = Date.now();
	// Record the time of last activity in localStorage
	localStorage.setItem(
		USER_LAST_ACTIVITY_DATE,
		lastUserActivityDate.toString()
	);
}

// Function to notify inactive tabs that it is necessary to logout
function logoutInOtherTabs(reason: string) {
	// Set the logout reason to localStorage
	localStorage.setItem(LOGOUT_REASON, reason);
}

// Function to logout on the current tab
function logoutInCurrentTab(reason: string, logoutFn: () => void) {
	// Call the logout function

	logoutFn();
}

// Variable that stores Timeout, which is responsible for the time of inactivity
let inactivityInterval: Timeout;
let sessionActivityInterval: Timeout;

// Callback function to respond to changes in storage
function handleStorageEvent(event: StorageEvent) {
	if (event.key === LOGOUT_REASON) {
		// Call the global function for logout if there is a reason for this in localStorage
		window.logout();
	} else if (event.key === USER_LAST_ACTIVITY_DATE) {
		if (event.newValue) {
			lastUserActivityDate = +new Date(+event.newValue);
		}
	} else if (event.key === LOG_OUT_STATUS) {
		if (event.newValue === LogoutStatuses.STAY_LOG_IN) {
			window.cancelLogout();
		}
	}
}

// Function to stop tracking user inactivity
function destroyLogout() {
	// Disable interval
	clearInterval(inactivityInterval);
	clearInterval(sessionActivityInterval);
	window.removeEventListener('storage', handleStorageEvent);
	USER_ACTIVITY_EVENTS.forEach((event) => {
		window.removeEventListener(event, notifyCurrentAndOtherTabsUserWasActive);
	});
}

/**
 * Initializing the logout system
 */
export function initLogout(
	logoutFn: () => void,
	showLogoutPopUpFn: () => void,
	cancelLogoutFn: () => void
) {
	/**
	 * We call this function during initialization in order to handle the situation,
	 * when the user opens the page and does not interact with it.
	 * In this case, we still need to start tracking inactivity
	 */
	notifyCurrentAndOtherTabsUserWasActive();

	// Clear the logout reason
	localStorage.setItem(LOGOUT_REASON, '');

	// Add a logout method to the global window object to exit the application
	window.logout = (reason = INACTIVITY) => {
		destroyLogout();
		logoutInOtherTabs(reason);
		logoutInCurrentTab(reason, logoutFn);
	};

	window.cancelLogout = () => {
		cancelLogoutFn();
	};

	// Set the interval to track user inactivity
	inactivityInterval = setInterval(() => {
		if (Date.now() - lastUserActivityDate > INACTIVE_LIMIT) {
			showLogoutPopUpFn();
			localStorage.setItem(LOG_OUT_STATUS, LogoutStatuses.LOG_OUT);
		}
	}, 1000);

	// Add a change listener to storage
	window.addEventListener('storage', handleStorageEvent);

	// Add listeners to all user activity events
	USER_ACTIVITY_EVENTS.forEach((event) => {
		window.addEventListener(event, notifyCurrentAndOtherTabsUserWasActive);
	});
}

const AuthContext = createContext<Omit<AuthState, 'loading'>>(initialAuthState);

function AuthProvider({ children }: PropsWithChildren) {
	const navigate = useNavigate();

	const { refetch: logoutRequest } = useLogout();
	const location = useLocation();
	const { state, pathname } = location;
	const [authorization, setAuthorization] = useState(getAuthState());
	const [enabledRedirect, setEnabledRedirect] = useState(isUserLoggedIn());
	const [isLogOutDialogOpen, setIsLogOutDialogOpen] = useState(false);
	const logOutRef = useRef<Timeout>();
	const { token } = authorization;
	const toast = useToast();

	useEffect(() => {
		// if user changes location
		if (!routesNotAssignableToPathKey.includes(pathname)) {
			localStorage.setItem(PATH_KEY, pathname);
		}

		// if user redirected to sign-in page (need to auth)
		if (
			state &&
			state.from.pathname !== pathname &&
			!routesNotAssignableToPathKey.includes(pathname)
		) {
			localStorage.setItem(PATH_KEY, state.from.pathname);
		}
	}, [state, pathname]);

	const broadcastChannel = new BroadcastChannel(BROADCAST_CHANNEL_NAME);

	const handleReceivedToken = useCallback<HandleReceivedToken>(
		(newToken) => {
			const newState: InitialAuthState = {
				...authorization,
				token: newToken,
			};
			setAuthorization(newState);
			setAuthState(newState);

			setEnabledRedirect(true);
		},
		[authorization]
	);

	const handleOpenLogOutDialog = useCallback(
		() => setIsLogOutDialogOpen(true),
		[]
	);
	const handleCloseLogOutDialog = useCallback(
		() => setIsLogOutDialogOpen(false),
		[]
	);

	const onCancelLogout = () => {
		handleCloseLogOutDialog();
		clearTimeout(logOutRef.current);
	};

	const onLogout = (reason?: LogoutReasons) => {
		removeAuthState();
		setAuthorization(initialAuthState);
		navigate(Routes.signIn);
		logoutInOtherTabs(reason ?? VIA_BUTTON);
		destroyLogout();
		logoutRequest();
		handleCloseLogOutDialog();
	};

	const handleStayLogIn = useCallback(() => {
		notifyCurrentAndOtherTabsUserWasActive();
		onCancelLogout();
		localStorage.setItem(LOG_OUT_STATUS, LogoutStatuses.STAY_LOG_IN);
	}, []);

	const handleLogOut = useCallback(() => {
		onLogout(INACTIVITY);
	}, []);

	useAxiosInterCeptors(token, onLogout);

	const { data } = useUserInfo(setAuthState, {
		isChangedOnlyTimeZone: false,
		enabled: Boolean(token),
		onSuccess: () => {
			if (enabledRedirect) {
				let origin = state?.from?.pathname ?? pathname;

				if (origin === Routes.authCallback) {
					origin = Routes.home;
				}

				navigate(origin);
			}
		},
	});

	useEffect(() => {
		const handleSessionSharing = (event: MessageEvent) => {
			if (event.data.type === SessionBroadcastEvents.requestSession) {
				if (sessionStorage.length) {
					const message: SessionBroadcastMessage = {
						type: SessionBroadcastEvents.sendSession,
						data: JSON.stringify(sessionStorage),
					};

					broadcastChannel.postMessage(message);
				}
			}
		};

		broadcastChannel.addEventListener('message', handleSessionSharing);

		return () =>
			broadcastChannel.removeEventListener('message', handleSessionSharing);
	}, []);

	useEffect(() => {
		if (!sessionStorage.length) {
			broadcastChannel.postMessage({
				type: SessionBroadcastEvents.requestSession,
			});
		}

		const handleGetSession = (event: MessageEvent) => {
			const message: SessionBroadcastMessage = event.data;

			if (message.type === SessionBroadcastEvents.sendSession) {
				if (sessionStorage.length) {
					return;
				}

				const storageInfo = JSON.parse(message.data as string);
				Object.keys(storageInfo || []).forEach((key) =>
					sessionStorage.setItem(key, storageInfo[key])
				);

				const path = localStorage.getItem(PATH_KEY) as string;
				navigate(path || Routes.home);

				window.location.reload();
			}
		};

		broadcastChannel.addEventListener('message', handleGetSession, {
			once: true,
		});
	}, []);

	const value = useMemo(
		() => ({
			...authorization,
			role: data?.position ?? authorization.role,
			...data,
			handleReceivedToken,
			onLogout,
			token,
		}),
		[authorization, data, token]
	);

	useEffect(() => {
		if (token && !sessionStorage.getItem(SESSION_EXPIRES)) {
			sessionStorage.setItem(
				SESSION_EXPIRES,
				String(Date.now() + SESSION_DURATION)
			);
		}

		if (token && sessionStorage.getItem(SESSION_EXPIRES)) {
			initLogout(onLogout, handleOpenLogOutDialog, onCancelLogout);
		}

		return () => {
			destroyLogout();
		};
	}, [token]);

	useEffect(() => {
		if (token) {
			const sessionExpireTime =
				Number(sessionStorage.getItem(SESSION_EXPIRES)) ?? 0;

			sessionActivityInterval = setInterval(() => {
				if (sessionExpireTime && Date.now() > sessionExpireTime) {
					onLogout(SESSION_EXPIRED);

					toast.open(
						createToast(
							NotificationType.INFO,
							'Session expired',
							'Your session has timed out. Please, log in again to continue working'
						)
					);
				}
			}, 1000);
		}

		return () => clearInterval(sessionActivityInterval);
	}, [token]);

	useEffect(() => {
		if (isLogOutDialogOpen) {
			logOutRef.current = setTimeout(() => {
				onLogout(INACTIVITY);
			}, SHOWING_LOG_OUT_POPUP_DURATION);

			return () => {
				clearTimeout(logOutRef.current);
			};
		}
	}, [isLogOutDialogOpen]);

	return (
		<AuthContext.Provider value={value}>
			{children}
			{isLogOutDialogOpen && (
				<LogOutPopUp
					handleClose={handleCloseLogOutDialog}
					handleStayLogIn={handleStayLogIn}
					handleLogOut={handleLogOut}
				/>
			)}
		</AuthContext.Provider>
	);
}

/**
 * Hook to use AuthContext.
 * Includes data about current user, authorization events and loading status.
 */
const useAuth = () => useContext(AuthContext) as Required<AuthState>;

export { AuthContext, AuthProvider, useAuth };
