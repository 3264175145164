export interface PersistentConfigurationItem<
	IdT = string,
	NameT = IdT,
	DescriptionT = IdT,
	LocationT = unknown
> {
	id: IdT;
	name: NameT;
	description: DescriptionT;
	location: LocationT | null;
}

export interface SimpleConfigurableConfigurationItem<IdT = string> {
	id: IdT;
	name: string;
}

export interface ComplexConfigurableConfigurationItem {
	id: string;
	shortName: string;
	fullName: string;
}

export enum LocationTypeId {
	Minsk = 'Minsk',
	Warsaw = 'Warsaw',
	Vitebsk = 'Vitebsk',
	Tbilisi = 'Tbilisi',
}

export enum PermissionTypeId {
	TrackBillableWorkingHours = 'TrackBillableWorkingHours',
}

export enum PermissionResourceTypeId {
	Project = 'Project',
}

export interface Configuration {
	persistentValues: PersistentValues;
	configurableValues: ConfigurableValues;
	features: FeatureFlag[];
}

interface PersistentValues {
	locationTypes: Array<LocationType>;
	roleTypes: Array<RoleType>;
	jsStackTypes: Array<JSStackType>;
	equipmentLocationTypes: Array<EquipmentLocationType>;
	equipmentAvailabilityTypes: Array<EquipmentAvailabilityType>;
	moneyCurrencyTypes: Array<MoneyCurrencyType>;
	permissionTypes: Array<PermissionType>;
	permissionResourceTypes: Array<PermissionResourseType>;
	scheduleTypes: Array<ScheduleType>;
	equipmentBrokenStatuses: Array<EquipmentBrokenStatus>;
}

export type LocationType = PersistentConfigurationItem;
export type RoleType = PersistentConfigurationItem;
export type JSStackType = PersistentConfigurationItem;
export type EquipmentLocationType = PersistentConfigurationItem;
export type EquipmentBrokenStatus = PersistentConfigurationItem;
export type EquipmentAvailabilityType = PersistentConfigurationItem;
export type MoneyCurrencyType = PersistentConfigurationItem;
export type PermissionType = PersistentConfigurationItem<PermissionTypeId>;
export type PermissionResourseType =
	PersistentConfigurationItem<PermissionResourceTypeId>;
export type ScheduleType = PersistentConfigurationItem;

interface ConfigurableValues {
	offices: Array<OfficeType>;
	departmentTypes: Array<DepartmentType>;
	equipmentTypes: Array<EquipmentType>;
	hardSkillGroups: Array<HardSkillGroupType>;
	seniorityLevels: Array<SeniorityLevelType>;
	generalSenioritySeniorityLevels: Array<SeniorityLevelType>;
	englishLevels: Array<EnglishLevelType>;
	softSkillSeniorityLevels: Array<SoftSkillsSeniorityLevelType>;
	countries: Array<CountryType>;
}

export interface OfficeWorkspace {
	id: number;
	name: string;
}

export interface OfficeType extends SimpleConfigurableConfigurationItem {
	location: LocationTypeId;
	workspaces: Array<OfficeWorkspace>;
}

export type DepartmentType = ComplexConfigurableConfigurationItem;
export type EquipmentType = ComplexConfigurableConfigurationItem;
export type HardSkillGroupType = SimpleConfigurableConfigurationItem;
export type SeniorityLevelType = SimpleConfigurableConfigurationItem;
export type EnglishLevelType = SimpleConfigurableConfigurationItem;
export type SoftSkillsSeniorityLevelType = SimpleConfigurableConfigurationItem;
export type CountryType = SimpleConfigurableConfigurationItem<number>;

export interface FeatureFlag {
	featureName: string;
	isEnabled: boolean;
	updatedAt: string;
}
